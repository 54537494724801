/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../../components/layout';
import Hero from '../../components/hero'
import Article from '../../components/article'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import { Stack } from 'raam'
import { graphql } from 'gatsby'
import Biography from '../../components/biography'
import { Byline } from '../../components/article'
import Promo from '../../components/promo'
import moment from 'moment'
import MetaData from '../../components/metadata'



const Single = (props) => {

  const page = props.data?.contentfulPlayer
  const team = props.data?.contentfulTeam
  const players = team?.groups.reduce((total, x) => ( total.concat(x.players) ), [])
  const playerIndex = players?.findIndex(x => x?.slug === page.slug)
  const indexBefore = playerIndex > 0 ? playerIndex - 1 : -1
  const indexAfter = 
    players && playerIndex < players.length-1 
        ? playerIndex + 1 
        : -1;
  const playerBefore = 
    players && indexBefore >= 0 
      ? players[indexBefore] 
      : false;
  const playerAfter = 
    players && indexAfter >= 0 
      ? players[indexAfter] 
      : false;

  return (
    <Layout {...props}>
      <MetaData title={page?.title} />
      <Hero
        back={props.pageContext?.back}
        data={[
          {
            type: "Bio",
            background: {
              desktop: page?.desktop?.file?.url,
              mobile: page?.mobile?.file?.url
            },
            number: page?.number,
            position: page?.position,
            primaryColour: page?.primaryColour,
            name: page?.title,
            quote: page?.quote,
            stats: [
              {
                label: 'Age',
                value: page?.age
              },
              {
                label: 'Apps',
                value: page?.apps
              },
              {
                label: 'Goals',
                value: page?.goals
              },
            ]
          }
        ]} 
      />
      <Byline 
        left={playerBefore?.title} 
        previous={playerBefore ? `/team/player/${playerBefore.slug}` : false} 
        right={playerAfter?.title} 
        next={playerAfter ? `/team/player/${playerAfter.slug}` : false}
        sameLine
      />
      
      <Stack gap={4} my={10}>
        <Wrapper>
          <Inner>
            <Article 
              {...page.article}
              reverse
              asideExtra={<Biography 
                data={[
                  {
                    label: 'Position',
                    value: page?.position
                  },
                  {
                    label: 'Country',
                    value: page?.country
                  },
                  {
                    label: 'Signed',
                    value: moment(page?.signed).format('DD.MM.YYYY')
                  },
                  {
                    label: 'Previous Clubs',
                    value: page?.previousClubs.reduce((string, x, i) => {
                      return string += `${x}${i < page.previousClubs.length-1 ? ', ' : ''}`
                    }, '')
                  },
                ]}
              />}
            />
          </Inner>
        </Wrapper>

        {page?.promo &&
          <Wrapper>
            <Inner>
              <Promo data={page?.promo} dark={props.light} />
            </Inner>
          </Wrapper>
        }
      </Stack>

    </Layout>
  )
}

export default Single;

export const pageQuery = graphql`
  query PlayerBySlug($slug: String!, $team: String!) {
    contentfulPlayer(slug: {eq: $slug}) {
      ...PlayerFragment
      promo {
        ...PromoFragment
      }
    }
    contentfulTeam(slug: {eq: $team}) {
      groups {
        displayTitle
        players {
          slug
          title
        }
      }
    }
  }
`