/** @jsx jsx */
import { jsx, Card, Text, Grid } from 'theme-ui';
import { responsive } from '../../utils/helpers';

const Biography = (props) => {

  const labelSX = {
    fontWeight: 700
  }

  const rowSX = {
    p: 2,
    borderBottom: '1px solid',
    borderColor: 'background'
  }

  const valueSX = {
    lineHeight: 'body'
  }

  return (
    <Card variant="slim">
      <Text variant="banner">Biography</Text>
      
        {props.data?.map((x,i) => {
          return (
            <Grid sx={rowSX} key={i} columns={responsive(1, '30% 1fr')} p={2}>
              <Text sx={labelSX}>{x.label}</Text>
              <Text as='p' sx={valueSX}>{x.value}</Text>
            </Grid>
          )
        })}
      
    </Card>
  )
}

export default Biography